import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Chip from './custom_chip';
import _ from 'lodash';
import axios from 'axios';
import APIUtils from '../utils/api_util';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { adicionarItem } from '../actions/index';
import toastr from 'toastr';
import RefreshAction from './refresh_action';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


const PADRAO_DESC = 30;
const habilitaOperacao = APIUtils.REACT_APP_HC;

class PedidoItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            estoque: '',
            erroEstoque: false,
            qtde: '',
            desconto: '',
            descontoPad: '',
            descontoManual: false,
            alertaDesc: false,
            erroDesconto: false,
            carregandoDesconto: false,
            observacao: '',
            erroObservacao: false,
            abrev: '',
            decimais: 0,
            btnHabilitado: false,
            consFin: false,
            vlrUni: ''
        };
        this.onQtdeBlur = this.onQtdeBlur.bind(this);
        this.onQtdeChange = this.onQtdeChange.bind(this);
        this.onAdicionarClick = this.onAdicionarClick.bind(this);
        this.onDesctoChange = this.onDesctoChange.bind(this);
        this.onVlrUniChange = this.onVlrUniChange.bind(this);
        this.onCfChange = this.onCfChange.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {    
        if(_.isEmpty(nextProps.prod) || _.isEmpty(nextProps.unidades)){
            // Produto vazio ou sem informacoes da tabela de unidades, nao devera ter informacoes da unidade
            if(prevState.abrev !== '' || prevState.decimais !== 0) {
                return { abrev: '', decimais: 0 };
            }
        }else{            
            const unidade = nextProps.unidades.find(u => u.id === nextProps.prod.idUN);
            if(unidade.abrev !== prevState.abrev || unidade.decimais !== prevState.decimais) {
                return { abrev: unidade.abrev, decimais: unidade.decimais, }; 
            }
        }

        return {};
    }    

    componentDidMount() {
        this.obterEstoque(this.props.prod);
        this.obterObservacao(this.props.prod);
    }    

    componentDidUpdate(prevProps) {        
        if (!_.isEqual(this.props.prod, prevProps.prod)) {
            this.obterEstoque(this.props.prod);
            this.obterObservacao(this.props.prod);

            const qtde = this.props.prod.qtde > 0 ? this.props.prod.qtde : '';
            const consFin = this.props.prod.consFin === true ? true : false;
            const desconto = this.props.prod.desconto > 0 ? this.props.prod.desconto : this.state.desconto;
            const vlrUni = this.props.prod.vlrVenda > 0 ? this.props.prod.vlrVenda : ''
            this.setState({ desconto, consFin, qtde, vlrUni });            
        } else if (this.props.idCli !== prevProps.idCli) {
            this.obterDesconto(this.props.prod, this.props.idCli, this.state.qtde);
        }        
    }


    async obterDesconto(prod, idCli, qtde) {        
        if (_.isEmpty(prod)) {
            this.setState({ desconto: '', descontoPad: '', btnHabilitado: false });
            return;
        }
        const idProd = prod.id;
        if (idProd > 0 && idCli > 0 && qtde !== '' && qtde >= 0) {
            this.setState({ carregandoDesconto: true });
            const qt = qtde === '' ? 0 : qtde
            const url = `${APIUtils.API_URL}/api/v1/valores/desconto/${idCli}/${idProd}/${qt}`;
            try {
                const response = await axios.get(url, { timeout: 7000 });
                const desconto = response.data;
                if(desconto > 0 || !this.state.descontoManual ) {
                    // Só irá atualizar se tiver um desconto ou que nao tenha inserido manual
                    this.setState({ desconto, descontoPad: desconto, erroDesconto: false, carregandoDesconto: false, btnHabilitado: true, descontoManual: false });
                }else{
                    this.setState({ carregandoDesconto: false, btnHabilitado: true });
                }
            } catch (e) {
                this.setState({ desconto: '', descontoPad: '', erroDesconto: true, carregandoDesconto: false, btnHabilitado: true });
            }
        } else {
            this.setState({ desconto: '', descontoPad: '', erroDesconto: false, btnHabilitado: false })
        }
    }

    async obterEstoque(prod) {
        if (_.isEmpty(prod)) {
            this.setState({ estoque: '' });
            return;
        }
        const idProd = prod.id;
        if (idProd > 0) {
            const url = `${APIUtils.API_URL}/api/v1/estoque/liberado/${idProd}`;
            try {
                const response = await axios.get(url);
                this.setState({ estoque: response.data, erroEstoque: false });
            } catch (e) {
                this.setState({ estoque: '', erroEstoque: true });
            }
        } else {
            this.setState({ estoque: '', erroEstoque: false })
        }
    }

    async obterObservacao(prod) {
        if (_.isEmpty(prod)) {
            this.setState({ observacao: '' });
            return;
        }
        const idProd = prod.id;
        if (idProd > 0) {
            const url = `${APIUtils.API_URL}/api/v1/produtos/observacao/${idProd}`;
            try {
                const response = await axios.get(url);
                this.setState({ observacao: response.data, erroObservacao: false });
            } catch (e) {
                this.setState({ observacao: '', erroObservacao: true });
            }
        } else {
            this.setState({ observacao: '', erroObservacao: false })
        }
    }

    onQtdeChange(values) {        
        this.setState({ qtde: values.value, btnHabilitado: false });
    }

    onQtdeBlur() {
        if(!_.isEqual(this.props.prod, this.prevProd) || this.props.idCli !== this.prevIdCli || this.state.qtde > 0) {

            // Caso alguma coisa tenha mudado, ira obter o desconto
            this.obterDesconto(this.props.prod, this.props.idCli, this.state.qtde);
            this.prevProd = this.props.prod;
            this.prevIdCli = this.props.idCli;
            this.prevQtde = this.state.qtde;        
        }                
    }

    onVlrUniChange(values) {       
        this.setState({ vlrUni: values.value, btnHabilitado: false});
    }

    onVlrUniBlur = () => {
        if (this.state.vlrUni > 0) {
            this.setState({ btnHabilitado: true })
        }        
    }

    onDesctoChange(values) {
        this.setState({ desconto: values.value, descontoManual: true });
    }

    onDesctoBlur = () => {
        if (this.state.qtde > 0) {
            this.setState({ btnHabilitado: true })
        }        
    }

    onCfChange(e) {
        this.setState({ consFin: e.target.checked });
    }

    onAdicionarClick(){
        const { desconto, descontoPad, erroDesconto } = this.state; 
        const vlrOrig = this.props.prod.vlrOrig ? this.props.prod.vlrOrig: this.props.prod.vlrVenda;

        if (this.props.parametros.descMax && vlrOrig !== 0) { 
            //Vlr unitario informado com desconto aplicado
            const vlrVendaDesc = this.state.vlrUni * (1 - desconto / 100);
            
            //Vlr original do produto com desconto
            const vlrOrigDescMax = vlrOrig*(1 - this.props.parametros.descMax/100)
        
            if (vlrVendaDesc < vlrOrigDescMax) {
                toastr.error(`Valor informado é superior ao limite máximo de desconto permitido de ${this.props.parametros.descMax}%`);
                return;
            }
        }

        if(!erroDesconto && descontoPad > 0){
            // Teve desconto padrao
            if(desconto > descontoPad){            
                this.setState({ alertaDesc: true });
            }else{
                this.adicionarItem();
            }                
        }else{
            // Nao teve desconto padrão ou erro obtendo o desconto padrão, irá efetuar a análise do limite padrao
            if(desconto > PADRAO_DESC) {
                this.setState({ alertaDesc: true });    
            }else{
                this.adicionarItem();    
            }
        }        
    }

    onAlertaDescClose(salvar) {
        this.setState({ alertaDesc: false});
        if(salvar) {
            this.adicionarItem();
        }
    }    

    adicionarItem() {
        if (_.isEmpty(this.props.prod)) {
            toastr.error("É preciso selecionar um produto");
            return;
        }
        if (this.state.qtde <= 0) {
            toastr.error("É preciso informar uma quantidade válida");
            return;
        }
        if (this.state.vlrUni <= 0) {
            toastr.error(habilitaOperacao ? "É preciso informar um valor unitário válido" : "É preciso informar um produto com valor válido");
            return;
        }

        const idProd = this.props.itens.find((item) => {
            return item.idProd === this.props.prod.id
        })

        if (idProd) {
            toastr.error("Produto informado já foi adicionado");
            return;
        }

        const alqDesc = this.state.desconto === '' ? 0 : this.state.desconto;
        this.props.adicionarItem(this.props.prod, this.state.vlrUni, this.state.qtde, alqDesc, this.state.consFin);
        this.setState({
            estoque: '',
            erroEstoque: false,
            qtde: '',
            desconto: '',
            descontoPad: '',
            erroDesconto: false,
            alertaDesc: false,
            observacao: '',
            erroObservacao: false,
            btnHabilitado: false,
            consFin: false,
            vlrUni: ''
        });
        this.props.limpar();
    }

    renderEstoque() {
        if (!_.isEmpty(this.props.prod)) {
            if (this.state.erroEstoque) {
                return <RefreshAction descr='Estoque' action={this.obterEstoque.bind(this, this.props.prod)} />
            } else {
                if (this.state.estoque === '') {
                    return <div>Obtendo estoque...</div>
                } else {
                    const cor = this.state.estoque > 0 ? 'green' : 'red';
                    return <div><Chip label={`Disponibilidade em estoque: ${this.state.estoque} ${this.state.abrev}`} color={cor} /></div>
                }
            }
        }
    }

    renderInformacoes() {
        if (!_.isEmpty(this.props.prod)) {
            return (
                <div>
                    {this.props.prod.promo === 'S' && <Chip label="Produto promocional!" color="red" />}
                </div>
            )
        }
    }
    renderObservacoes() {
        if (!_.isEmpty(this.props.prod)) {
            if (this.state.erroObservacao) {
                return <RefreshAction descr='Observações do produto' action={this.obterObservacao.bind(this, this.props.prod)} />
            } else {
                if(this.state.observacao === ''){
                    return null;
                }else{
                    return (
                        <div style={{ padding: 10, background: '#eee', borderRadius: 10 }}>
                            <p style={{ margin: 0, fontSize: 12 }} align="justify">
                                {this.state.observacao}
                            </p>
                        </div>
                    )
                }
            }
        }
    }
  

    renderQtde() {
        const { classes } = this.props;
        return (
            <NumberFormat
                type="text"
                value={this.state.qtde}
                isNumericString={true}
                onValueChange={this.onQtdeChange}
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={this.state.decimais}
                onBlur={this.onQtdeBlur}
                customInput={TextField}
                style={{ flex: 3 }}
                className={classes.flexItem}
                label={`Qtde (${this.state.abrev})`}
                disabled={_.isEmpty(this.props.prod)}
                decimalSeparator=","
            />
        );
    }

    renderVlr() {
        const { classes } = this.props;
        return (
            <NumberFormat
                type="text"
                value={this.state.vlrUni}
                isNumericString={true}
                onValueChange={this.onVlrUniChange}
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={2}
                onBlur={this.onVlrUniBlur}
                customInput={TextField}
                InputProps={{
                    endAdornment: <InputAdornment position="end">($)</InputAdornment>
                }}
                style={{ flex: 3 }}
                className={classes.flexItem}
                label='Valor unitário'
                disabled={_.isEmpty(this.props.prod) || this.state.carregandoDesconto}
                decimalSeparator=","
            />
        );
    }

    renderDesc() {
        const { classes } = this.props;
        return (
            <NumberFormat
                type="text"
                value={this.state.desconto}
                isNumericString={true}
                onValueChange={this.onDesctoChange}
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={2}
                onBlur={this.onDesctoBlur}
                customInput={TextField}
                InputProps={{
                    endAdornment: <InputAdornment position="end">(%)</InputAdornment>,
                    error: this.state.erroDesconto
                }}
                style={{ flex: 2 }}
                className={classes.flexItem}
                label='Desc.'
                disabled={_.isEmpty(this.props.prod) || this.state.carregandoDesconto}
                decimalSeparator=","
            />         
        );
    }


    renderAlertaDesc() {
        const descMax = this.state.descontoPad > 0 ? this.state.descontoPad : PADRAO_DESC;
        return (
            <div>
                <Dialog open={this.state.alertaDesc} onClose={this.onAlertaDescClose.bind(this)}>
                    <DialogTitle>{"Atenção!"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Você aumentou o percentual de desconto máximo permitido para esse item (de {descMax} % para {this.state.desconto} %). Deseja continuar?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>                    
                        <Button onClick={this.onAlertaDescClose.bind(this, false)} color="primary" autoFocus>Não</Button>
                        <Button onClick={this.onAlertaDescClose.bind(this, true)} color="primary">Sim</Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }


    render() {
        const { classes } = this.props;

        return (
            <div style={{ width: '100%' }}>
                <FormControlLabel
                    control={<Checkbox value='cf' checked={this.state.consFin} onChange={this.onCfChange}/>}
                    label='Consumidor final'
                />            
                <div style={{ marginBottom: 20 }} className={classes.flex}>
                    
                    {this.renderVlr()}
                    {this.renderQtde()}
                    {this.renderDesc()}
                    
                    <div className={classes.flexButton}>
                        <Fab size="small" color="primary" aria-label="add" onClick={this.onAdicionarClick} disabled={!this.state.btnHabilitado}>
                            <AddIcon />
                        </Fab>
                    </div>
                </div>
                {this.renderEstoque()}
                {this.renderInformacoes()}
                {this.renderObservacoes()}
                {this.renderAlertaDesc()}
            </div>
        );
    }
}

const styles = ({
    flex: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '100%'
    },

    flexItem: {
        padding: '0 10px 0 0',
        '& > div, label': {
            fontSize: 13
        }
    },

    flexButton: {
        alignSelf: 'center'
    }
})

function mapStateToProps(state) {    
    return {        
        unidades: state.unidades,
        itens: state.itensPedido,
        parametros: state.parametros
    };
}

export default connect(mapStateToProps, { adicionarItem })(withStyles(styles)(PedidoItem));